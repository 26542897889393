import { UPDATE_FILTER, RESET_FILTER, UPDATE_USERFILTER, RESET_USERFILTER } from "../actions/types";
const initialState = {
  selectedbrand: null,
  selectedSource: null,
  selectedStatus: null,
  selectedModratedBy: null,
};

//Reducers
export default function (state = initialState, { type, payload = {} }) {
  switch (type) {
    case UPDATE_FILTER:
      return {
        ...payload,
      };

    case RESET_FILTER:
      return {
        ...payload,
      };

    case UPDATE_USERFILTER:
      return {
        ...payload,
      };

    case RESET_USERFILTER:
      return {
        ...payload,
      };

    default:
      return state;
  }
}
