import React from "react";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import UseStyles from "./style";
import Logo from "../../../assets/img/unilever.png";
import Container from "@material-ui/core/Container";

export default function Footer(props) {
  const { classes: propCSS = "" } = props;
  const classes = UseStyles();
  return (
    <Box
      component="div"
      className={`${classes.footerWrap} ${propCSS.bottomFixed}`}
    >
      <Container spacing={5} className={classes.footerBlock}>
        <Box component="div" xs={12} spacing={3}>
          <img src={Logo} className="App-logo" alt="logo" width={65} height={72} />
        </Box>
      </Container>
    </Box>
  );
}

Footer.propTypes = {
  classes: PropTypes.object,
};
