import { RESET_REVIEWS, UPDATE_REVIEWS, UPDATE_RECORD } from "../actions/types";

const initialState = {
  rating: null,
};
//Reducers
export default function (state = initialState, { type, payload = {} }) {
  switch (type) {
    case UPDATE_REVIEWS:
      return {
        ...payload,
      };

    case RESET_REVIEWS:
      return {
        ...payload,
      };

    case UPDATE_RECORD:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
}
