import { combineReducers } from "redux";
import Login from "./loginReducer";
import Auth from "./authReducer";
import Curated from "./curateReducer";
import Navigation from "./navigationReducer";
import Filter from "./filterReducer";
import Data from "./reviewsReducer";
import UserData from "./userDataReducer";

const reducer = combineReducers({
  Login,
  Auth,
  Curated,
  Navigation,
  Filter,
  Data,
  UserData,
});
export default reducer;
