import React from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import UseStyles from "./style";
import Logo from "../../../assets/img/kritique-logo.svg";
import Container from "@material-ui/core/Container";
import LogoutButton from "../../molecules/logoutButton/logoutButton";
import { isLoggedIn } from "../../../helpers/localStoreHandler";

export default function Header(props) {
  const classes = UseStyles();
  const { Auth } = useSelector((state) => state);
  return (
    <Box component="div" className={classes.HeaderWrap}>
      <Container className={classes.HeaderBlock}>
        <Box component="div" xs={12} spacing={3}>
          <Link to={"/login"}>
            <img src={Logo} className="App-logo" alt="logo" width={178} height={48} />
          </Link>
        </Box>
        {Auth.isLoggedIn && (
          <Box component="div" xs={12} sm={4} className={classes.LoginButton}>
            <LogoutButton />
          </Box>
        )}
      </Container>
    </Box>
  );
}
