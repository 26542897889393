export const SHOW_PASSWORD = "SHOW_PASSWORD";
export const PASSWORD_VALUE = "PASSWORD_VALUE";
export const USERNAME_VALUE = "USERNAME_VALUE";
export const SUBMIT_FORM = "SUBMIT_FORM";
export const FORM_ERROR = "FORM_ERROR";
export const LOGOUT = "LOGOUT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REST_FORM = "REST_FORM";

export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_REVOKE = "AUTH_REVOKE";

export const USER_INFO = "USER_INFO";

export const CURATE_ROLES = "CURATE_ROLES";
export const CURATE_CAPABILITIES = "CURATE_CAPABILITIES";
export const RESET_CURATE = "RESET_CURATE";

export const IS_OPEN = "IS_OPEN";
export const NAV_MENU_LIST = "NAV_MENU_LIST";
export const RESET_NAV = "RESET_NAV";

export const UPDATE_FILTER = "UPDATE_FILTER";
export const RESET_FILTER = "RESET_FILTER";
export const UPDATE_USERFILTER = "UPDATE_USERFILTER";
export const RESET_USERFILTER = "RESET_USERFILTER";

export const UPDATE_REVIEWS = "UPDATE_REVIEWS";
export const RESET_REVIEWS = "RESET_REVIEWS";
export const UPDATE_RECORD = "UPDATE_RECORD";

export const UPDATE_USER_FILTER = "UPDATE_USER_FILTER";
export const UPDATE_USERSDATA = "UPDATE_USERSDATA";
export const RESET_USERSDATA = "RESET_USERSDATA";
