import { makeStyles } from "@material-ui/core/styles";
import loginBackground from "../../assets/img/login_bg.jpg";
import errorIcon from "../../assets/img/errorIcon.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 0,
    maxWidth: "100%",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "40px 10px",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  heroContent: {
    padding: "10px 0 70px",
  },
  heroTitle: {
    padding: "40px 0 20px",
  },
  gridBlock: {
    background: "transparent",
    boxShadow: "none",
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  },
  gridBorder: {
    borderLeft: "1px solid #ddd",
    [theme.breakpoints.down("sm")]: {
      border: "none",
    },
  },
  innerGrid: {
    boxShadow: "none",
    background: "transparent",
  },
  intro: {
    fontWeight: "500",
    lineHeight: "1.6rem",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      lineHeight: "1.5rem",
    },
  },
  alignCenter: {
    textAlign: "center",
  },
  h2: {
    padding: "0 15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
    },
  },
  flexblock: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  button: {
    margin: "5px 10px",
    padding: "5px 25px"
  },
  paper: {
    marginTop: "0",
    display: "flex",
    flexDirection: "column",
    padding : "25px 0 0 ",
    alignItems: "center",
    textAlign: "center",
    borderTop: "1px solid #C9DAE6",
    width: "100%"
  },
  wrapper: {
    flex: "1",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  accessWrapper : {
    flexGrow: 1,
    maxWidth: "100%",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    backgroundImage: `url(${loginBackground})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    position: "relative",
    color: "#000000",
    "& h2": {
      color: "#000000",
    }
  },
  login: {
    flex: "inherit",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    boxShadow: "0px 0px 11px 0px #d9e2f1",
    minHeight: "518px",
    background: "#f5f8fa",
    alignItems: "center",
    borderRadius: "10px",
    //backgroundImage: `linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(${loginbg1})`,
    backgroundSize: "contain",
    padding: "45px",
    flexDirection: "column",
    "& img" : {
      paddingBottom: "45px"
    }
  },
  errorStyle: {
    fontSize: ".7rem",
    border: "1px solid #7719BC",
    padding: "10px",
    marginBottom: "25px",
    borderRadius: "5px",
    background: "rgb(253, 236, 234)",
    "& span": {
      fontSize: ".8rem",
      display: "block",
      marginBottom: "5px",
      color: "red",
      background: `url(${errorIcon})`,
      backgroundPosition: "left",
      backgroundRepeat: "no-repeat",
      paddingLeft: "16px",
      "& svg": {
        float: "left",
        margin: "2px 2px 0 0"
      },
    },
    "& a": {
      color: "#7719BC",
      fontWeight: "700"
    },
  },
  copyText: {
    color: "#1F36C7",
    display: "block",
    textAlign: "center",
    margin: "30px 0 0 0"
  }
}));

export default useStyles;
