import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import UseStyles from "./style";
import Container from "@material-ui/core/Container";

function HeroLayout(props) {
  const { backgroundClassName, children } = props;
  const classes = UseStyles();

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        {children}
        <div className={classes.backdrop} />
        <div className={clsx(classes.background, backgroundClassName)} />
      </Container>
    </section>
  );
}

HeroLayout.propTypes = {
  backgroundClassName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
};

export default HeroLayout;
