import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Logo from "../../../assets/img/kritique-logo-small.svg";

const NavMobile = (props) => {
  const {
    classes,
    userMenu,
    handleDrawerOpen,
    handleDrawerClose,
    openNav,
    setOpenNav,
  } = props;

  const { Navigation, Curated } = useSelector((state) => state);
  const { path, url, params } = useRouteMatch();

  return (
    <Drawer
      open={openNav}
      onClose={() => setOpenNav(!openNav)}
      className={classes.navDrawer}
    >
      <List className={classes.leftSpacing}>
        <div className={classes.logoContainer}>
          <img src={Logo} className={classes.mobilelogo} alt="logo" />
        </div>
        {userMenu.map((text, index) => (
          <Link
            to={`/dashboard/${text}`}
            key={index}
            className={classes.mobilelink}
          >
            <ListItem
              button
              className={classes.navButton}
              selected={params.screen === text ? true : false}
            >
              <ListItemIcon>
                {index % 2 === 0 ? (
                  <InboxIcon className={classes.icon} />
                ) : (
                  <MailIcon className={classes.icon} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={Curated.roles[text]["title"]}
                classes={{ primary: classes.navText }}
                className={`${classes.nav}`}
              />
            </ListItem>
          </Link>
        ))}
      </List>
    </Drawer>
  );
};
NavMobile.propTypes = {
  classes: PropTypes.object.isRequired,
  userMenu: PropTypes.array.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  openNav: PropTypes.bool.isRequired,
  setOpenNav: PropTypes.func.isRequired,
};
export default NavMobile;
