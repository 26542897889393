const { CURATE_ROLES, CURATE_CAPABILITIES } = require("./types");

export const curateRole = (e) => (disptch) => {
  disptch({
    type: CURATE_ROLES,
    payload: {
      roles: e,
    },
  });
  return Promise.resolve();
};

export const curateCapablility = (e) => (disptch) => {
  disptch({
    type: CURATE_CAPABILITIES,
    payload: {
      capability: e,
    },
  });
  return Promise.resolve();
};
