import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    label: {
        marginBottom: theme.spacing(2),
        color: theme.palette.common.black,
        fontWeight: 'bold'
    },
    TableContainer: {
        boxShadow: '0 0 2px 0',
    },
    Table: {
        tableLayout: 'fixed',
        "& .MuiTableRow-root": {
            verticalAlign: "top",
        },
        "& .MuiFormControl-marginDense": {
            marginTop: "0px",
        },
    },
    TableHead: {
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.down('xs')]: {
            height: '1px',
            padding: '0',
            position: 'absolute',
            width: '1px',
            overflow: 'hidden',
        },
    },
    TableHeadCell: {
        color: theme.palette.common.white,
        fontWeight: 'bold',
    },

    TableCell: {
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            '&::before': {
                content: 'attr(data-label)',
                float: 'left',
                marginBottom: '5px',
            },
        },
    },
    tooltip: { float: "right" },
    error: {
        color: theme.palette.error.main,
    },
    iconTable: {
        verticalAlign: "middle",
    },
    buttonIcon: {
        padding: 0,
    },

    selectField: {
        fontSize: "13px",
    },
    dropdownStyle: {
        boxShadow: "none",
        borderRadius: 0,
        border: "1px solid #ddd",
        "& .MuiList-root": {
            padding: 0,
            maxHeight: "220px",
        },
        "& .MuiMenuItem-root": {
            padding: "3px 10px",
            fontSize: "13px",
        },
    },
}));
export default useStyles;