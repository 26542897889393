import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import NavDesktop from "../navDesktop/navDesktop";
import NavMobile from "../navMobile/navMobile";
import withWidth from "@material-ui/core/withWidth";
import UseStyles from "./style";
import { navigationState } from "../../../actions/navigationActionCreator";

const Navigation = React.memo((props) => {
  const { setOpenNav, openNav, width } = props;
  const { Navigation, Curated } = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = UseStyles();

  const handleDrawerOpen = () => {
    dispatch(navigationState(true));
  };
  const handleDrawerClose = () => {
    dispatch(navigationState(false));
  };
  const userMenu = Object.keys(Curated.roles);

  useEffect(() => {
    const status = Navigation.isOpen || false;
    dispatch(navigationState(status));
  }, []);

  return (
    <Box component="div" className={classes.navigationWrapper}>
      {width === "lg" || width === "xl" ? (
        <NavDesktop
          userMenu={userMenu}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
          classes={classes}
        />
      ) : (
          <NavMobile
            userMenu={userMenu}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            openNav={openNav}
            setOpenNav={setOpenNav}
            classes={classes}
          />
        )}
    </Box>
  );
});
Navigation.propTypes = {
  openNav: PropTypes.bool.isRequired,
  setOpenNav: PropTypes.func.isRequired,
};
export default withWidth()(Navigation);
