import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  HeaderWrap: {
    maxWidth: "100%",
    padding: 0,
    background: "#ffffff",
    width: "100%",
    bottom: 0,
    flex: 0,
    boxShadow: "2px 0px 4px #ddd",
  },
  HeaderBlock: {
    padding: "20px",
    display: "flex !important",
    alignItems: "center",
  },
  LoginButton: {
    marginLeft: "auto !important",
  },
}));
export default useStyles;
