import AuthService from "../services/auth.services";
import { removeAllStorage } from "../helpers/localStoreHandler";

const {
  AUTH_REVOKE,
  RESET_CURATE,
  RESET_NAV,
  RESET_FILTER,
  RESET_REVIEWS,
} = require("./types");

export const logout = (e) => (dispatch) => {
  return AuthService.logout().then((data) => {
    // removeAllStorage();

    removeAllStorage();
    dispatch({
      type: AUTH_REVOKE,
      payload: {},
    });
    dispatch({
      type: RESET_CURATE,
      payload: {},
    });
    dispatch({
      type: RESET_NAV,
      payload: {},
    });
    dispatch({
      type: RESET_FILTER,
      payload: {},
    });
    dispatch({
      type: RESET_REVIEWS,
      payload: {},
    });

    removeAllStorage();

    return Promise.resolve(data);
  }).catch((e) => {
    removeAllStorage();
    return Promise.resolve();
  });
};
