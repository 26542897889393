//service for accessing user viewable data
import axios from "axios";
import { isAuth } from "../helpers/localStoreHandler";
import { questionCarelinesModeration } from "../config";
const isCarelinesModerationEnabled = function (brand, locale, index) {
  let carelinesModeration = questionCarelinesModeration;

  return (
    ((carelinesModeration.hasOwnProperty("*") &&
      (carelinesModeration["*"].includes("*") ||
        carelinesModeration["*"].includes(locale))) && (index == -1 || carelinesModeration.excluded[index].locale.indexOf(locale) == -1)) ||
    ((carelinesModeration.hasOwnProperty(brand) &&
      carelinesModeration[brand].includes(locale)) && (index == -1 || carelinesModeration.excluded[index].locale.indexOf(locale) == -1))
  );
};

const getModeratorBoard = (data) => {
  data["page"] = data.page - 1;
  const filterType = data.filterDataType ? data.filterDataType : data.filterType;
  const apiURL = filterType === 'question' ? process.env.REACT_APP_QUESTION_API_URL + "/questions/search" : process.env.REACT_APP_REVIEW_API_URL + "/reviews/search";
  return axios
    .post(
      apiURL,
      {
        ...data,
      },
      {
        headers: {
          "x-auth-key": isAuth(),
        },
        withCredentials: true,
      }
    )
    .then((response) => {
      if (filterType === 'question') {
        response.data.questions.map((question, index) => {
          let qindex = questionCarelinesModeration.excluded.findIndex(item => item.brand === question.brand);
          let qnaFlag = isCarelinesModerationEnabled(question.brand, question.locale, qindex)
          response.data.questions[index]['moderationExportStatus'] = qnaFlag;
        });
      }
      return response;
    })
    .catch((e) => {
      return Promise.reject({
        status: e.response.status,
        error:
          (e.response && e.response.statusText) ||
          "Unable to connect server. Please try later",
      });
    });
};

const putOnboardingForm = (data) => {
  return axios
    .post(
      process.env.REACT_APP_CONFIG_API_URL +
        `/config/${data.brand}/${data.locale}`,
      {
        ...data,
      },
      {
        headers: {
          "x-auth-key": isAuth(),
        },
        withCredentials: true,
      }
    )
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((e) => {
      return Promise.reject({
        status: e.response.status,
        error:
          (e.response && e.response.statusText) ||
          "Unable to connect server. Please try later",
      });
    });
};

const addUserApiRoles = (data, brand, locale) => {
  return axios
    .post(
      process.env.REACT_APP_USER_API_URL +
        `/user${brand && locale ? "/" + brand + "/" + locale : ""}`,
      {
        ...data,
      },
      {
        headers: {
          "x-auth-key": isAuth(),
        },
        withCredentials: true,
      }
    )
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((e) => {
      return Promise.reject({
        status: e.response.status,
        error:
          (e.response && e.response.data?.message) ||
          "Unable to connect server. Please try later",
      });
    });
};

const getWidgetConfiguration = (data) => {
  const x = new Date()
  var UTCseconds = (x.getTime() + x.getTimezoneOffset()*60*1000)/1000;
  return axios
    .get(
      process.env.REACT_APP_CONFIG_API_URL +
        `/config/${data.brand}/${data.locale}?q=`+parseInt(UTCseconds),
      {
        headers: {
          "x-auth-key": isAuth(),
        },
        withCredentials: true,
      }
    )
    .then(async (response) => {
        return Promise.resolve(response);
    })
    .catch((e) => {
      return Promise.reject({
        status: e.response.status,
        error:
          (e.response && e.response.statusText) ||
          "Unable to connect server. Please try later",
      });
    });
};

const getSignupConfiguration = async (locale) =>{
      const countryCode = locale.split('-');
      try {
        const configDataResponse = await axios.get(`https://config.unileversolutions.com/config/ctrm?iso2CountryCode=${countryCode[1].toUpperCase()}`);
        const region = configDataResponse.data.responseData.Items[0].regionCode === 'NA' ? 'US' : configDataResponse.data.responseData.Items[0].regionCode;
        let x_apikey = await axios.get(process.env.REACT_APP_CONFIG_API_URL +
            `/config/signUpconfiguration/${region}`,
          {
            headers: {
              "x-auth-key": isAuth(),
            },
            withCredentials: true,
          });
        x_apikey = x_apikey.data
        return Promise.resolve({region,x_apikey});
      } catch (error) {
        return error
      }
}

const getOptinData = (data) => {
  return axios
    .get(
      process.env.REACT_APP_OPTIN_API_URL +
        `?iso2CountryCode=${data.locale
          .split("-")[1]
          .toUpperCase()}&brandCode=${data.brand}&language=${data.locale.split("-")[0]}`
    )
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((e) => {
      return Promise.reject({
        status: e.response.status,
        error:
          (e.response && e.response.statusText) ||
          "Unable to connect server. Please try later",
      });
    });
};

const postWidgetConfiguration = (data) => {
  return axios
    .post(
      process.env.REACT_APP_CONFIG_API_URL +
        `/config/${data.brand}/${data.locale}`,
      {
        ...data,
      },
      {
        headers: {
          "x-auth-key": isAuth(),
        },
        withCredentials: true,
      }
    )
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((e) => {
      return Promise.reject({
        status: e.response.status,
        error:
          (e.response && e.response.statusText) ||
          "Unable to connect server. Please try later",
      });
    });
};
const postRoleStatusChange = (data) => {
  return axios
    .post(
      process.env.REACT_APP_USER_API_URL + `/user/updateUserRole`,
      { ...data },
      {
        headers: {
          "x-auth-key": isAuth(),
        },
        withCredentials: true,
      }
    )
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((e) => {
      return Promise.reject({
        status: e.response.status,
        error:
          (e.response && e.response.statusText) ||
          "Unable to connect server. Please try later",
        message: e.response.data.message
      });
    });
};

const updateEntityType = (data) => {
  return axios
    .put(
      process.env.REACT_APP_REVIEW_API_URL +
        `/review/${data.brand}/${data.locale}/${data.reviewId}`,
      {
        ...data,
      },
      {
        headers: {
          "x-auth-key": isAuth(),
        },
        withCredentials: true,
      }
    )
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((e) => {
      return Promise.reject({
        status: e.response.status,
        error:
          (e.response && e.response.statusText) ||
          "Unable to connect server. Please try later",
      });
    });
};

const updateQuestionType = (data) => {
  return axios
    .put(
      process.env.REACT_APP_QUESTION_API_URL +
        `/question/${data.brand}/${data.locale}/${data.questionId}`,
      {
        ...data,
      },
      {
        headers: {
          "x-auth-key": isAuth(),
        },
        withCredentials: true,
      }
    )
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((e) => {
      return Promise.reject({
        status: e.response.status,
        error:
          (e.response && e.response.statusText) ||
          "Unable to connect server. Please try later",
      });
    });
};

const getUserDataBoard = (data) => {
  data["page"] = data.page > 0 ? data.page - 1 : 0;
  return axios
    .post(
      process.env.REACT_APP_USER_API_URL + "/user/getAllUsers",
      {
        ...data,
      },
      {
        headers: {
          "x-auth-key": isAuth(),
        },
        withCredentials: true,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((e) => {
      console.log(e);
      return Promise.reject({
        status: e.response.status,
        error:
          (e.response && e.response.statusText) ||
          "Unable to connect server. Please try later",
      });
    });
};

export {
  getModeratorBoard,
  putOnboardingForm,
  getWidgetConfiguration,
  postWidgetConfiguration,
  addUserApiRoles,
  updateEntityType,
  updateQuestionType,
  getUserDataBoard,
  getOptinData,
  getSignupConfiguration,
  postRoleStatusChange,
};
