import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router, BrowserRouter } from "react-router-dom";
import Store from "./store";
import { history } from "./helpers/history";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from "./services/auth.config";
import DateFnsUtils from "@date-io/date-fns";
import Theme from "./theme";
import "./i18n";
import moment from 'moment';
import EventEmitter from 'events';

import "./index.css";
import App from "./pages/App";

const constants = {
  USER_FORCELOGOUT_TIMEOUT: { amount: 48, unit: 'h' }
}
const eventEmitter = new EventEmitter();

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  //msalInstance.setActiveAccount(sampleAccount);
  //console.log("login:: ",msalInstance.getActiveAccount());
}

/**
 * To set an active account after the user signs in, register an event and listen for LOGIN_SUCCESS and LOGOUT_SUCCESS. For more,
 * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
 */
msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    const setActiveAccountPromise = msalInstance.setActiveAccount(account);
    //localStorage.setItem('userDetails', account)
    // redirect user
    const refererUrl = localStorage.getItem('refererUrl');
    // save current time
    const lastLoginTime = moment()
    console.log("set last login index: ", lastLoginTime);
    localStorage.setItem('lastLogin', lastLoginTime.format());
    // set timer to force logout after duration set in config
    setTimeout(() => {
      localStorage.removeItem('lastLogin')
      // msalInstance.logoutRedirect({
      //   account: account,
      //   postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri
      // });
      console.log("settime index execute");
    }, moment.duration(constants.USER_FORCELOGOUT_TIMEOUT.amount, constants.USER_FORCELOGOUT_TIMEOUT.unit).asMilliseconds() )
    if (refererUrl) {
      localStorage.removeItem('refererUrl');
      eventEmitter.emit('redirectUser', {
        pathName: refererUrl,
        userDetails: account
      });
    } else {
      eventEmitter.emit('redirectUser', {
        pathName: '/campaign-wizard',
        userDetails: account
      });
    }
  }

  if (event.eventType === EventType.LOGOUT_SUCCESS) {
    // track logout
    const t = localStorage.getItem('cwtoken');
    eventEmitter.emit('logoutTrack', {
      e: 'logoutIndex',
      t: t
    });
  }
});

window.msalInstance = msalInstance;
ReactDOM.render(

  <MsalProvider instance={msalInstance}>
    <Provider store={Store}>
      <ThemeProvider theme={Theme}>
        <BrowserRouter>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <App />
          </MuiPickersUtilsProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </MsalProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
