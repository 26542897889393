import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";

export default function FullWidthContentBlock(props) {
  const { children, classes } = props;

  return (
    <Grid container spacing={0} className={classes.blockColor}>
      <Grid item sm={12}>
        {children}
      </Grid>
    </Grid>
  );
}
FullWidthContentBlock.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
};
