import { makeStyles } from "@material-ui/core/styles";
import backgroundImage from "../../../assets/img/welcome-banner.jpg";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,

    backgroundPosition: "center",
  },
  contentBlock: {
    textAlign: "center",
  },
  button: {
    minWidth: 200,
  },
  h2: {
    fontSize: `4rem`,
    marginBottom: `5px`,
    [theme.breakpoints.up("sm")]: {
      fontSize: `6rem`,
    },
  },
  h3: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
}));
export default useStyles;
