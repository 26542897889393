import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Redirect, useLocation, useHistory } from "react-router-dom";
import { logout } from "../actions/loginActionCreator";
import { isAuth, removeAllStorage } from "../helpers/localStoreHandler"

const ProtectedRoute = ({ render: Component, identifier, ...rest }) => {
  //const isAuth = true; // to be replaced by jwt auth
  const dispatch = useDispatch();
  const { Auth } = useSelector((state) => state);
  const location = useLocation();
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (Auth.isLoggedIn) {
          if (Auth.user.isActive && identifier === "dashboard") {
            if (isAuth()) {
              return Component;
            } else {
              dispatch(logout());
              return null;
            }
          } else {
            if (identifier === "access") {
              return Component;
            } else {
              return <Redirect to="/access-request" />;
            }
          }
        } else {
          //dispatch(logout());
          if (isAuth()) {
            removeAllStorage();
          }
          //return <Redirect to="/login" />;
          history.push({ pathname: '/login', state: { from: location.pathname }});
        }
      }}
    />
  );
};

export default ProtectedRoute;
