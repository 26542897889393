
export function getLoggedInUser(msalInstance) {
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'local'
    ) {
      return {
        username: process.env.REACT_APP_USER_EMAIL || 'test@unilever.com',
        name: process.env.REACT_APP_USER_NAME || 'John Doe'
      };
    }
    return msalInstance.getActiveAccount();
  }