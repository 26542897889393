import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "100%",
    padding: 0,
  },
  footerWrap: {
    flex: 0,
    maxWidth: "100%",
    padding: 0,
    background: "#f5f5f5",
    width: "100%",
    bottom: 0,
    boxShadow: "0px 0px 4px #ddd",
  },
  footerBlock: {
    padding: "20px",
    background: "#f5f5f5",
  },
}));
export default useStyles;
