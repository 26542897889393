import { UPDATE_USERSDATA, RESET_USERSDATA, UPDATE_USER_FILTER } from "../actions/types";

const initialState = {
};
//Reducers
export default function (state = initialState, { type, payload = {} }) {
    switch (type) {
        case UPDATE_USERSDATA:
            return {
                ...payload,
            };

        case RESET_USERSDATA:
            return {
                ...payload,
            };
        case UPDATE_USER_FILTER:
            return {
                ...payload,
            };
        // case UPDATE_RECORD:
        //     return {
        //         ...state,
        //         ...payload,
        //     };

        default:
            return state;
    }
}
