import { makeStyles } from "@material-ui/core/styles";
import backgroundImage from "../../../assets/img/welcome-right-bg.jpg";

const useStyles = makeStyles((theme) => ({
  h2: {
    fontWeight: `300`,
    fontSize: `30px`,
    textTransform: `uppercase`,
    textAlign: `left`,
    marginBottom: `20px`,
  },
  alignRight: {
    textAlign: `left`,
  },
  gutter30: {
    padding: `30px 35px 30px 15px`,
  },
  contetnWrapper: {
    maxWidth: `527px`,
    marginLeft: `auto`,
    marginBottom: `35px`,
  },
  imgBlock: {
    position: `relative`,
    height: `250px`,
    flexBasis: `100%`,
    [theme.breakpoints.up("sm")]: {
      height: `inherit`,
      flexBasis: `50%`,
    },
  },
  background: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: -2,
    backgroundPosition: "center",
  },
}));
export default useStyles;
