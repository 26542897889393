const getLocalStoreItem = (data) => {
  // try {
  //   var re = new RegExp(data + "=([^;]+)");
  //   var value = re.exec(document.cookie);
  //   return value != null ? unescape(value[1]) : null;
  // } catch (e) {
  //   return false;
  // }
  return localStorage.getItem(data);
};
const setLocalStoreItem = (data, value) => {
  //document.cookie = `${data}=${value};expires=0; path=/`;
  //sessionStorage.setItem(data, value);
  localStorage.setItem(data, value)
};
const isLoggedIn = () => {
  const result = getLocalStoreItem("isLoggedIn") === "true" ? true : false;
  return result;
};
const isActiveUser = () => {
  return getLocalStoreItem("isActive");
};
const removeAllStorage = () => {
  //sessionStorage.clear();
  localStorage.clear();

};
const isAuth = () => {
  //return getLocalStoreItem("jwt");
  //return True if JWT token exists
  try {
    var re = new RegExp("jwt" + "=([^;]+)");
    var value = re.exec(getLocalStoreItem("usercookie"));
    const token = value != null ? unescape(value[1]) : null;
    if (token) {
      return token;

    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

export {
  getLocalStoreItem,
  setLocalStoreItem,
  removeAllStorage,
  isLoggedIn,
  isActiveUser,
  isAuth,
};
