import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, useRouteMatch } from "react-router-dom";
import UseStyles from "./style";
import Header from "../../components/organisms/header/header";
import Footer from "../../components/organisms/footer/footer";
import OnboardingFrom from "../../components/templates/onboardingForm/onboardingForm";
import IntermediaryScreen from "../../components/templates/intermediaryScreen/intermediaryScreen";

const AccessRequest = (props) => {
  const classes = UseStyles();
  const { params } = useRouteMatch();
  const { Auth } = useSelector((state) => state);

  const loadScreens = () => {
    if (params.screen === "onboarding-form") {
      return <OnboardingFrom />;
    } else if (!params.screen) {
      return <IntermediaryScreen classes={classes} />;
    } else {
      return <Redirect to="/page-not-found" />;
    }
  };

  if (Auth.isLoggedIn) {
    if (Auth.user.isActive) {
      return <Redirect to="/dashboard" />;
    }
  } else {
    return <Redirect to="/login" />;
  }

  return (
    <>
      {params.screen && <Header />}
      {loadScreens()}
      {params.screen && <Footer classes={classes} />}
    </>
  );
};

export default AccessRequest;
