import React, { useState, useEffect, Suspense } from "react";
import clsx from "clsx";
import UseStyles from "./style";
import { Route, Switch, useRouteMatch, Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Navigaiton from "../../components/organisms/navigation/navigation";
import ProgressBar from "../../components/molecules/progressBar/progressBar";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import Config from "../../config";
import ProtectedRoute from "../../routes/protectedRoute";
import { logout } from "../../actions/loginActionCreator";

const Dashboard = React.memo((props) => {
  const { Auth, Curated, Navigation } = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = UseStyles();
  const history = useHistory();
  const { path, params, url } = useRouteMatch();
  const [openNav, setOpenNav] = useState(false);

  const getScreenTemplate = (slug) => {
    try {
      if (slug) {
        if (
          Object.keys(Curated.roles).indexOf(slug) >= 0 &&
          Object.keys(Config.screens).indexOf(slug) >= 0
        ) {
          return Config.screens[slug];
        } else {
          throw Error;
        }
      } else {
        const defaultDash = Object.keys(Curated.roles);
        if (!defaultDash.length) {
          throw Error;
        }
        if (defaultDash.indexOf(Config.defaultDashboard) >= 0) {
          return <Redirect to={`${url}/${Config.defaultDashboard}`} />;
        } else {
          const dashboard = defaultDash[0];
          return <Redirect to={`${url}/${dashboard}`} />;
        }
      }
    } catch (e) {
      console.log(e);
      if (Auth.isLoggedIn) {
        alert(` Roles cannot be curated, Please check if user has proper access. Logging Out User`);

        dispatch(logout()).then(() => {
          return <Redirect to="/login" />;
        });
        return null;
      }

      return <Redirect to="/login" />;
    }
  };



  return (
    <>
      <Navigaiton
        className={classes.navigaitonBlock}
        openNav={openNav}
        setOpenNav={setOpenNav}
      />
      <div
        className={clsx(
          classes.infoBoard,
          {
            [classes.infoBoardShift]: Navigation.isOpen,
          },
          classes.screen
        )}
      >
        <Hidden lgUp implementation="css">
          <MenuIcon
            className={classes.hamburger}
            onClick={() => setOpenNav(!openNav)}
          />
        </Hidden>

        <Suspense fallback={<ProgressBar />}>
          {getScreenTemplate(params.screen)}
        </Suspense>
      </div>
    </>
  );
});

export default Dashboard;
