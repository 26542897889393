import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 230;
const useStyles = makeStyles((theme) => ({
  menuButton: {
    position: "absolute",
    right: "-22px",
    bottom: " 26%",
    zIndex: "9999",
    paddingLeft: "35px",
  },
  navigationWrapper: {
    width: "auto",
    position: "fixed",
    left: 0,
    height: "100%",
    background: "transparent",
    overflow: "visible",
    zIndex: "999",
    marginTop: 20,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  navButton: {
    paddingLeft: "15px",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "30px",
    },
    "&.Mui-selected::after": {
      content: "''",
      position: "absolute",
      top: "0",
      height: "100%",
      right: "0",
      width: "2px",
      background: "#5e81f4",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "40px",
    },
  },
  icon: {
    fill: "#5e81f4 !important",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 20,
    },
  },
  logo: {
    width: "100%",
    maxWidth: "180px",
    display: "block",
    margin: "15px auto 0",
    padding: "0 10px",
    boxSizing: "border-box",
  },
  mobilelogo: {
    maxWidth: "120px",
    display: "block",
    margin: "15px auto 0",
    padding: "0 10px",
    boxSizing: "border-box",
    height: "50px",
    width: "50px"
  },
  logoContainer: {
    marginBottom: "25px",
    marginTop: "15px",
  },
  arrowButton: {
    background: "#5e81f4",
    fill: "#fff !important",
    borderRadius: " 50%",
    marginLeft: "-23px",
  },
  navText: {
    fontWeight: "bold",
    fontSize: "15px",
    color: "#000",
  },
  nav: {
    transition: "opacity .3s ease-in-out",
  },
  hideNav: {
    transition: "opacity .3s ease-in-out",
    opacity: 0,
  },
  link: {
    textDecoration: "none",
  },
  navDrawer: {
    "& .MuiPaper-root": {
      width: "200px",
    },
  },
}));

export default useStyles;
