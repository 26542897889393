import roles from "../user.roles";
import Config from "../config";

//pass roles payload to get curated roles
export const roleCurator = (userRoles) => {
  return userRoles.reduce((total, currentValue, currentIndex, arr) => {
    if (total.indexOf(currentValue.role) === -1) {
      if (currentValue.isApproved) {
        total.push(currentValue.role);
      }
    }
    return total;
  }, []);
};

//Curated roles passed to get curate capabilities
export const roleCapabilities = (curatedRoles, userRoles) => {
  const c = roles;
  return curatedRoles.reduce((total, currentValue) => {
    if (c[currentValue]) {
      const brandsMapped = userRoles.filter((item) => {
        if (item.role === currentValue) {
          return item;
        }
      });
      Object.keys(c[currentValue]).forEach((item) => {
        let existingBrand = [];
        let existingLocale = []
        if (total[item]) {
          existingBrand = total[item].brands || [];
          existingLocale = total[item].locales || [];
        }
        if (!total[item]) {
          total[item] = { ...c[currentValue][item] };
        };
        total[item]["brands"] = existingBrand;
        total[item]["locales"] = existingLocale;
        if (total[item]["brands"].length > 0) {
          const accumilated = [];
          brandsMapped.forEach((i) => {
            if (total[item]["brands"].indexOf(i) === -1) {
              accumilated.push(i);
            }
          });
          total[item]["brands"] = [...accumilated, ...existingBrand]
        } else {
          total[item]["brands"] = brandsMapped;
        }

        if (total[item]["locales"].length > 0) {
          const accumilated = [];
          brandsMapped.forEach((i) => {
            if (i.role == 'Brand_Admin' || i.role == 'KQ_Admin') {
              Config.locales.forEach(function (locale) {
                var roleObj ={ isApproved: true, brand: i.brand, locale: locale, role: i.role }
                if (total[item]["locales"].indexOf(roleObj) === -1) {
                  accumilated.push(roleObj);
                }
              })
            }
            // if (total[item]["brands"])
            if (total[item]["locales"].indexOf(i) === -1) {
              accumilated.push(i);
            }
          });
          total[item]["locales"] = [...accumilated, ...existingBrand]
        } else {
          total[item]["locales"] = brandsMapped;
        }
      });
      total = { ...total };
    }
    return total;
  }, {});
};

//pass curated roles and roles payload to get roles mapped brand and local
export const roleMapper = (curatedRoles, userRoles) => {
  return curatedRoles.reduce((accumulated, current) => {
    let capability = userRoles.reduce(
      (total, currentValue, currentIndex, arr) => {
        if (currentValue.role === current) {
          if (currentValue.isApproved) {
            total.push(currentValue);
          }
        }
        return total;
      },
      []
    );
    accumulated[current] = capability;
    return accumulated;
  }, {});
};
