import React from "react";
import Config from "../config";

const GetBrandAndLocals = (screen) => {
  if (screen.brands.find(({ role }) => { return role === Config.kritiqueAdmin })) {
    return []

  } else if (screen.brands.find(({ role }) => { return role === Config.brandAdmin })) {
    return screen.brands.reduce((total, current) => {
      if (current.role === Config.brandAdmin) {
        total.push({ brand: current.brand });
      } else {
        total.push({ brand: current.brand, locale: current.locale });
      }
      return total;
    }, []);
  } else {
    return screen.brands.map((item) => {
      return {
        locale: item.locale,
        brand: item.brand,
      };
    });
  }
};

const GetBrandNames = (screen) => {
  if (screen.brands.find(({ role }) => { return role === Config.kritiqueAdmin })) {
    return Config.brand;

  } else {
    return screen.brands.reduce((total, current) => {
      if (total.indexOf(current.brand) === -1) {
        total.push(current.brand);
      }
      return total;
    }, []);
  }
};

const GetLocales = (screen) => {
  if (screen.locales.find(({ role }) => { return role === Config.kritiqueAdmin })) {
    return Config.locales;

  } else {
    return screen.locales.reduce((total, current) => {
      if (total.indexOf(current.locale) === -1) {
        total.push(current.locale);
      }
      return total;
    }, []);
  }
};

/*Function to validate params brand locale with role owner brand locale*/
const GetInitialBrandAndLocals = (screen, params, initialBrands, initialLocale) => {
  let brandLocale = {};
  if (params.brand && initialBrands.includes(params.brand)) {
    brandLocale.brand = params.brand;
  }
  if (params.locale && initialLocale.includes(params.locale)) {
    brandLocale.locale = params.locale;
  }
  if (brandLocale)
    return [brandLocale];
  else
    return GetBrandAndLocals(screen);
};

/* Function to validate params brand locale*/
const validateParams = (screen, params) => {
  let validatedParams = {};
  let validBrand = GetBrandNames(screen);
  let validLocale = GetLocales(screen);
  validatedParams.id = params?.id && (params.id == 'review' || params.id == 'question') ? params.id : 'review';
  validatedParams.brand = params?.brand && validBrand.includes(params.brand.toUpperCase()) ? params.brand.toUpperCase() : '';
  validatedParams.locale = validatedParams.brand && params?.locale && validLocale.includes(params.locale.toLowerCase()) ? params.locale.toLowerCase() : params.locale?.toLowerCase();
  return validatedParams;
};

/*Function to update moderation screen browser url */
const updateScreenHistory = (validatedParams, history) => {
  if (validatedParams.id && !validatedParams.brand) {
    history.replace({ pathname: '/dashboard/moderation/' + validatedParams.id }); 
  }else if (validatedParams.id && validatedParams.brand && !validatedParams.locale) {
    history.replace({ pathname: '/dashboard/moderation/' + validatedParams.id + '/' + validatedParams.brand }); 
  }else if (validatedParams.id && validatedParams.brand && validatedParams.locale) {
    history.replace({ pathname: '/dashboard/moderation/' + validatedParams.id + '/' + validatedParams.brand + '/' + validatedParams.locale}); 
  }
};

export { GetBrandAndLocals, GetBrandNames, GetLocales, validateParams, updateScreenHistory, GetInitialBrandAndLocals};
