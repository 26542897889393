import React, {useState } from 'react'
import Markdown from 'react-markdown'
import { useParams } from 'react-router-dom';
import onboardingKritique from '../../assets/mdFiles/onboardingKritique.md';
import kritiqueTheming from '../../assets/mdFiles/themingKritique.md';
import moderatingReviews from '../../assets/mdFiles/kritiqueModeratingReviews.md';
import widgetConfiguration from '../../assets/mdFiles/widgetConfiguration.md';
import widgetIntegrationguide from '../../assets/mdFiles/widgetIntegrationguide.md';
import reviewSyndication from '../../assets/mdFiles/reviewSyndication.md';
import moderatorGuidelines from '../../assets/mdFiles/moderatorGuidelines.md';
import kritiqueLaunch from '../../assets/mdFiles/kritiqueLaunch.md';
import './style.css';

function Onboarding() {
    const [src, setSrc] = useState();
    var raw;
    const { guideName } = useParams();
    if (guideName == 'kritique-onboarding') {
        raw = onboardingKritique;
    } else if (guideName == 'kritique-theming') {
        raw = kritiqueTheming;
    } else if (guideName == 'moderating-reviews') {
        raw = moderatingReviews;
    } else if (guideName == 'widget-configuration') {
        raw = widgetConfiguration;
    } else if (guideName == 'widget-integration') {
        raw = widgetIntegrationguide;
    }  else if (guideName == 'review-syndication') {
        raw = reviewSyndication;
    } else if (guideName == 'moderator-guidelines') {
        raw = moderatorGuidelines;
    } else if (guideName == 'kritique-launch') {
        raw = kritiqueLaunch;
    }
    fetch(raw)
        .then(r => r.text())
        .then(text => {
            console.log('text decoded:', text);
            setSrc(text)
        });
    return (
        <div className="markdown-container">
            <Markdown>{src}</Markdown>
        </div>
    )
}

export default Onboarding;