import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { logout } from "../../../actions/loginActionCreator";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { msalConfig } from '../../../services/auth.config';
import { useMsal } from '@azure/msal-react';
import { getLoggedInUser } from "../../../helpers/utils";
const LogoutButton = (props) => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    removeSessionOnClose();
  }, []);

  const removeSessionOnClose = (e) => {
    window.addEventListener("beforeunload", function (e) {
      e.preventDefault();
      dispatch(logout()).then(() => {
        history.push("/login");
      });                          //Webkit, Safari, Chrome
    });
  };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout()).then(() => {
      const userDetails = getLoggedInUser(instance) || {};

      instance.logoutRedirect({
        account: userDetails,
        postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri
      });
    });
  };
  return (
    <Button type="submit" variant="contained" onClick={handleLogout}>
      {t("logout.button.label")}
    </Button>
  );
};

export default LogoutButton;
