import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import Box from "@material-ui/core/Box";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Roles from "../../../user.roles";
import useStyles from "./style";

const Fieldsrow = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { Curated } = useSelector((state) => state);
  const { rows, setRows, errorsRow, setErrorsRow, roleOptions } = props;
  const maxAccessType = 3;
  const [disabledField, setDisabledField] = useState();
  const [showMaxerror, setShowMaxerror] = useState(false);

  let fields = [
    {
      name: "email",
      label: "Email",
      type: "email",
      value: "",
    },
    {
      name: "role",
      label: "Role",
      type: "select",
      value: "",
      options: roleOptions,
    },
  ];

  const handleChange = (idx, e) => {
    const { name, value } = e.target;

    let userInfo = [...rows],
      userFields = [...userInfo[idx]];

    let updateError = { ...errorsRow };
    if (updateError[idx]) {
      updateError[idx][name] = "";
      setErrorsRow({ ...updateError });
    }
    setShowMaxerror(false);
    userFields.find((o) => o.name === name)["value"] = value;
    userInfo[idx] = userFields;
    setRows(userInfo);


    if (rows.length > maxAccessType - 1) {
      validateAccessType(idx);
    }
  };

  const validateAccessType = (id) => {
    let access = rows.map((row) => {
      return row.find((o) => o.name === "role")["value"];
    });

    let accessCountArr = access.reduce(function (obj, b) {
      obj[b] = ++obj[b] || 1;
      return obj;
    }, {});

    for (var i in accessCountArr) {
      if (accessCountArr[i] >= maxAccessType) {
        setDisabledField(i);
      }
    }
  };

  const handleAddRow = () => {
    if (rows.length < 2 * maxAccessType) {
      setRows([...rows, fields]);
    }
  };

  const handleRemoveRow = (id) => {
    if (rows.length > 1) {
      const newRows = rows.filter((item, index) => {
        if (index !== id) { return item }
      })
      setRows(newRows);
    }
  };

  return (
    <Box mt={3} mb={3}>
      <InputLabel shrink htmlFor="platform" className={classes.label}>
        {props.tableLabel}
      </InputLabel>
      <TableContainer component={Paper} className={classes.TableContainer}>
        <Table aria-label="table" className={classes.Table}>
          <TableHead className={classes.TableHead}>
            <TableRow>
              <TableCell className={classes.TableHeadCell} style={{ width: "46%" }}>
                {props.thirdColumn}
              </TableCell>
              <TableCell className={classes.TableHeadCell} style={{ width: "46%" }}>
                {props.fourthColumn}
              </TableCell>
              <TableCell className={classes.TableHeadCell} style={{ width: "8%" }}>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((items, idx) => (
              <TableRow key={idx}>
                {items.map((item, id) =>
                  item.type === "select" ? (
                    <TableCell
                      className={classes.TableCell}
                      data-label={props.fourthColumn}
                      key={id}
                    >
                      <Select
                        variant="outlined"
                        labelId={item.name}
                        id={item.name}
                        margin="dense"
                        name={item.name}
                        value={item.value}
                        required
                        fullWidth
                        displayEmpty
                        defaultValue=""
                        className={classes.selectField}
                        onChange={(event) => handleChange(idx, event)}
                        {...(errorsRow &&
                          errorsRow[idx] &&
                          errorsRow[idx][item.name] && { error: true })}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          classes: { paper: classes.dropdownStyle },
                        }}
                      >
                        <MenuItem value="">
                          <em>Select Role</em>
                        </MenuItem>
                        {item.options.sort().map((field, idx) => (
                          <MenuItem
                            value={field.value}
                            disabled={field.disable ? true : false}
                            key={idx}
                          >
                            {field.name.split('_').join(' ')}
                          </MenuItem>
                        ))}
                      </Select>
                      {showMaxerror && (
                        <FormHelperText className={classes.error}>
                          Maximum {maxAccessType} users can have same access
                          type.
                        </FormHelperText>
                      )}
                      {errorsRow && errorsRow[idx] && errorsRow[idx][item.name] && (
                        <FormHelperText className={classes.error}>
                          {errorsRow[idx][item.name]}
                        </FormHelperText>
                      )}

                    </TableCell>
                  ) : (
                      <TableCell
                        className={classes.TableCell}
                        data-label={props.firstColumn}
                        key={id}
                      >
                        <TextField
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          name={item.name}
                          label={item.label}
                          type={item.type}
                          id={item.name}
                          value={item.value}
                          InputProps={{
                            classes: {
                              input: classes.selectField,
                            },
                          }}
                          {...(errorsRow &&
                            errorsRow[idx] &&
                            errorsRow[idx][item.name] && { error: true })}
                          onChange={(event) => handleChange(idx, event)}
                        />
                        {errorsRow && errorsRow[idx] && errorsRow[idx][item.name] && (
                          <FormHelperText className={`${classes.error} `}>
                            {errorsRow[idx][item.name]}
                          </FormHelperText>
                        )}
                      </TableCell>
                    )
                )}
                <TableCell className={`${classes.TableCell} ${classes.iconTable}`}
                >
                  {idx > 0 && (
                    <IconButton color="primary" className={classes.buttonIcon} aria-label="Delete item" component="span" onClick={() => handleRemoveRow(idx)}>
                      <DeleteOutlinedIcon />
                    </IconButton>
                  )

                  }


                </TableCell>
              </TableRow>
            ))}

          </TableBody>
        </Table>
        <Box m={3}>
          <Grid justify="flex-end" container>
            <Button
              variant="contained"
              onClick={() => handleAddRow()}
              color="primary"
              size="small"
              type="button"
            >
              {props.addRowLabel}
            </Button>

          </Grid>
        </Box>
      </TableContainer>
    </Box >
  );
};

Fieldsrow.propTypes = {
  tableLabel: PropTypes.string.isRequired,
  firstColumn: PropTypes.string.isRequired,
  secondColumn: PropTypes.string.isRequired,
  addRowLabel: PropTypes.string.isRequired,
  deleteRowLabel: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  fieldValue: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  roleOptions: PropTypes.array.isRequired,
  setRows: PropTypes.func.isRequired,
  setErrorsRow: PropTypes.func.isRequired,
  errorsRow: PropTypes.object.isRequired,
};

export default Fieldsrow;
