import { AUTH_SUCCESS, AUTH_FAIL, AUTH_REVOKE } from "../actions/types";
import { getLocalStoreItem } from "../helpers/localStoreHandler";

//const initState = JSON.parse(getLocalStoreItem("state"));
const initState = getLocalStoreItem("state");
const user = initState && initState.Auth ? initState.Auth.user : {};

const initialState = user;

export default function (state = initialState, { type, payload = {} }) {
  switch (type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case AUTH_REVOKE:
      return {
        ...payload,
      };
    default:
      return state;
  }
}
