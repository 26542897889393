import React from "react";
import PropTypes from "prop-types";
import UseStyles from "./style";
import Grid from "@material-ui/core/Grid";

export default function SideBySideBlock(props) {
  const { children, classes } = props;
  const internalclasses = UseStyles();

  return (
    <Grid container spacing={0}>
      <Grid item sm={6}>
        {children}
      </Grid>
      <Grid item sm={6} className={internalclasses.imgBlock}>
        <div
          className={`${internalclasses.background} ${classes.sideBySidebBackground}`}
        />
      </Grid>
    </Grid>
  );
}
SideBySideBlock.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
};
