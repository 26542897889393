const roles = {
  Moderator: {
    moderation: { title: "Moderation", capability: "RM", readOnly: false },
    developer: { title: "Developer Portal", capability: "DP", readOnly: false },
    //support: { title: "Support", capability: "RE", readOnly: false },
    //reports: { title: "Reports", capability: "RE", readOnly: false },
  },
  Agency_User: {
    configure: { title: "Configure", capability: "BSC", readOnly: false },
    developer: { title: "Developer Portal", capability: "DP", readOnly: false },
    //support: { title: "Support", capability: "RE", readOnly: false },
  },
  Brand_Admin: {
    moderation: { title: "Moderation", capability: "RM", readOnly: false },
    configure: { title: "Configure", capability: "BSC", readOnly: false },
    support: { title: "Support", capability: "RE", readOnly: false },
    management: { title: "User Management", capability: "UM", readOnly: false },
    developer: { title: "Developer Portal", capability: "DP", readOnly: false },

    // reports: { title: "Reports", capability: "RE", readOnly: false },
  },
  KQ_Admin: {
    moderation: { title: "Moderation", capability: "RM", readOnly: true },
    configure: { title: "Configure", capability: "BSC", readOnly: false },
    support: { title: "Support", capability: "RE", readOnly: false },
    management: { title: "User Management", capability: "UM", readOnly: false },
    developer: { title: "Developer Portal", capability: "DP", readOnly: false },
    //reports: { title: "Reports", capability: "RE", readOnly: true },
  },
};
export default roles;
