import React, { useState } from "react";
import PropTypes from "prop-types";
import validator from "validator";
import {
  Button,
  Grid,
  InputLabel,
  FormControl,
  FormHelperText,
  Typography,
  Container,
  Paper,
  TextField,
  CssBaseline,
} from "@material-ui/core/";
import { Autocomplete } from '@material-ui/lab';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import UseStyles from "./style";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Config from "../../../config";
import Roles from "../../../user.roles";
import Capitalize from "../../../helpers/capitalize";
import Fieldsrow from "../../molecules/fieldsrow/fieldsrow";
import { addUserApiRoles } from "../../../services/user.services";
import { GetBrandNames } from "../../../helpers/getBrandLocalsForScreen";

export default function OnboardingForm(props) {
  const { t } = useTranslation();
  const classes = UseStyles();
  const { externalClass } = props;
  const { Curated } = useSelector((state) => state);
  const brands = GetBrandNames(Curated.roles.support);
  const brandsMapped = brands.reduce((total, current) => {
    if (Config.brandList[current]) {
      total.push({ "name": Config.brandList[current], "value": current });
    }
    return total;
  }, []);
  const roleOptions = Object.keys(Roles).reduce((total, role) => {
    if (Object.keys(Curated.capability).indexOf(Config.kritiqueAdmin) === -1) {
      if (role !== Config.kritiqueAdmin && role !== Config.brandAdmin)
        total.push({
          name: role,
          value: role,
          disable: false,
        });
    } else {
      total.push({
        name: role,
        value: role,
        disable: false,
      });
    }
    return total;
  }, []);
  const [roles, setRoles] = useState([
    [
      {
        name: "email",
        label: "Email",
        type: "email",
        value: "",
      },
      {
        name: "role",
        label: "Role",
        type: "select",
        value: "",
        options: roleOptions,
      },
    ],
  ]);
  const [fieldValue, setValue] = useState({
    brand: "",
    country: "",
    language: ""
  });
  const [errors, setErrors] = useState({});
  const [errorsRow, setErrorsRow] = useState({});
  const [submitError, setSubmitError] = useState(null);
  const [successResp, setSuccessResp] = useState(null);
  const [selectedLocale, setLocale] = useState([]);

  const country = Config.countryList;
  const language = Config.languageList;

  const updateCountry = (event, val) => {
    let country = val ? val.value : '', err = [];
    setValue({
      ...fieldValue,
      ['country']: country,
    });
    if (country) {
      err['country'] = "";
    } else {
      err['country'] = t("form.error.required");
    }
    setErrors({
      ...err,
    });
  };
  const updateLanguage = (event, val) => {
    let language = val ? val.value : '', err = [];
    setValue({
      ...fieldValue,
      ['language']: language,
    });
    if (language) {
      err['language'] = "";
    } else {
      err['language'] = t("form.error.required");
    }
    setErrors({
      ...err,
    });
  };
  const handleBlur = (event) => {
    const { name, value } = event.target;
    validate({ [name]: value });
  };
  const validate = (fieldValue) => {
    let err = { ...errors };
    let valid = true;
    for (var key in fieldValue) {
      if (fieldValue[key]) {
        err[key] = "";
      } else {
        valid = false;
        err[key] = t("form.error.required");
      }
    }
    setErrors({
      ...err,
    });
    return valid;
  };
  const validateRows = (fieldValue) => {
    let errRow = {};
    let valid = true;
    let emailCollector = [];

    for (var key in fieldValue) {
      let err = {};
      for (var i in fieldValue[key]) {
        if (i === "email") {
          if (fieldValue[key][i]) {
            if (validator.isEmail(fieldValue[key][i])) {
              err[i] = "";
            } else {
              valid = false;
              err[i] = t("loginForm.form.email.error");
            }
          } else {
            valid = false;
            err[i] = t("form.error.required");
          }
        } else if (i === "role") {
          if (fieldValue[key][i]) {
            if (Object.keys(Roles).indexOf(fieldValue[key][i]) >= 0) {
              err[i] = "";
            } else {
              valid = false;
              err[i] = t("form.accessType.invalid.error");
            }
          } else {
            valid = false;
            err[i] = t("form.error.required");
          }

        } else {
          if (fieldValue[key][i]) {
            err[i] = "";
          } else {
            valid = false;
            err[i] = t("form.error.required");
          }
        }
      }
      errRow[key] = err;
    }
    setErrorsRow({ ...errorsRow, ...errRow });
    return valid;
  };

  const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  const handleSnakbarErrorClose = () => {
    setSubmitError("");
  };
  const handleSnakbarSuccessClose = () => {
    setSuccessResp("");
  };

  const resetRows = () => {
    setRoles([
      [
        {
          name: "email",
          label: "Email",
          type: "email",
          value: "",
        },
        {
          name: "role",
          label: "Role",
          type: "select",
          value: "",
          options: Object.keys(Roles).map((role) => {
            return {
              name: role,
              value: role,
              disable: false,
            };
          }),
        },
      ],
    ])
  };
  const updateBrand = (event, val) => {
    let brand = val ? val.value : '', err = [];
    const relatedLocales = Curated.roles.configure.brands.reduce(
      (total, current) => {
        if (
          current.brand === brand &&
          total.indexOf(current.locale) === -1
        ) {
          total.push(current.locale);
        }
        return total;
      },
      []
    );
    setValue({
      ...fieldValue,
      ['brand']: brand,
    });
    if (brand) {
      err['brand'] = "";
    } else {
      err['brand'] = t("form.error.required");
    }
    setErrors({
      ...err,
    });
    setLocale(relatedLocales);

  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let err = {};
    let role = roles.keys();

    for (let i of role) {
      err[i] = roles[i].reduce((total, current) => {
        total[current.name] = current.value;
        return total;
      }, []);
    }
    const isValidRows = validateRows(err);
    const isValidFields = validate(fieldValue);

    if (isValidRows && isValidFields) {
      const locale = `${fieldValue.language}-${fieldValue.country}`;
      const payload = { ...fieldValue };
      payload["locale"] = locale;
      const filtredRoles = roles.reduce((total, current) => {
        const Item = {
          emailId: current.find((o) => o.name === "email").value,
          isActive: true,
          roles:
            [{
              role: current.find((o) => o.name === "role").value,
              locale: locale,
              brand: fieldValue.brand,
            }],
        };
        total.push(Item);
        return total;
      }, []);

      let rec = [];
      let emails = [];
      let passEmails = [];
      filtredRoles.forEach(async (roles) => {
        const brand = roles.roles[0] && roles.roles[0].brand || "";
        const locale = roles.roles[0] && roles.roles[0].locale || "";
        rec.push(addUserApiRoles(roles, brand, locale));
        emails.push(roles.emailId);
      });
      
      Promise.allSettled(rec).
        then((results) => {
          results.forEach((result) => {
            if (result.value && result.value.config && result.value.config.data) {
              passEmails.push(JSON.parse(result.value.config.data).emailId);
            }
          });
          let failedEmails = emails.filter(email => passEmails.indexOf(email) < 0);
          if(results[0].status ==="rejected" && results[0].reason.status === 400 ) {
            setSubmitError(`${results[0].reason.error}`);
            setSuccessResp('');
          }else if (failedEmails.length > 0) {
            setSubmitError(`Following record cannot be updated: ${failedEmails.toString(" ")}`);
            setSuccessResp('');
          } else {
            setSuccessResp(`Form has been submitted`);
            setSubmitError('');
            setValue({
              brand: "",
              country: "",
              language: ""
            });
            resetRows();
            setLocale([]);
          }
        });
    }
    
  };
  return (
    <Container maxWidth="lg">
      <CssBaseline />
      <Paper className={`${classes.paper} ${externalClass && externalClass.externalBg}`}>
        {successResp !== "" && (
          <Snackbar
            open={successResp ? true : false}
            autoHideDuration={Config.modelMsgTimer}
            onClose={handleSnakbarSuccessClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="success">{successResp}</Alert>
          </Snackbar>
        )}



        {submitError !== "" && (
          <Snackbar
            open={submitError ? true : false}
            autoHideDuration={Config.modelMsgTimer}
            onClose={handleSnakbarErrorClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity="error">{submitError}</Alert>
          </Snackbar>
        )}
        <Typography
          component="h2"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
          className={classes.h2}
        >
          {t("onBoardingForm.title")}
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>

            <Grid item xs={12} sm={4} md={4}>
              <FormControl fullWidth>
                <InputLabel
                  shrink
                  htmlFor={"brand"}
                  className={classes.label}
                >
                  {t("onboarding.brandName.label")}
                </InputLabel>
                <Autocomplete
                    labelId={"brand"}
                    id={"brand"}
                    options={brandsMapped}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.name === value.name }
                    renderOption={option => <span className={classes.autocompleteOption}>{option.name}</span>}
                    renderInput={(params) => <TextField {...(errors && errors["brand"] && { error: true })} placeholder="select the value" {...params} variant="outlined" />}
                    onChange={updateBrand}
                    className={classes.selectField}
                />
                {errors && errors["brand"] && (
                  <FormHelperText className={classes.error}>
                    {errors["brand"]}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <FormControl fullWidth>
                <InputLabel shrink htmlFor={"country"} className={classes.label}>
                  {t("onboarding.country.label")}
                </InputLabel>
                <Autocomplete
                    labelId={"country"}
                    id={"country"}
                    options={country}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.name === value.name }
                    renderOption={option => <span className={classes.autocompleteOption}>{option.name}</span>}
                    renderInput={(params) => <TextField {...(errors && errors["country"] && { error: true })} placeholder="select the value" {...params} variant="outlined" />}
                    onChange={updateCountry}
                  className={classes.selectField}
                  disabled={fieldValue["brand"] ? false : true}
                />
                {fieldValue.brand && errors && errors["country"] && (
                  <FormHelperText className={classes.error}>
                    {errors["country"]}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <FormControl fullWidth>
                <InputLabel shrink htmlFor={"language"} className={classes.label}>
                  {t("onboarding.language.label")}
                </InputLabel>
                <Autocomplete
                    labelId={"language"}
                    id={"language"}
                    options={language}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.name === value.name }
                    renderOption={option => <span className={classes.autocompleteOption}>{option.name}</span>}
                    renderInput={(params) => <TextField {...(errors && errors["language"] && { error: true })} placeholder="select the value" {...params} variant="outlined" />}
                    onChange={updateLanguage}
                  className={classes.selectField}
                  disabled={fieldValue["brand"] && fieldValue["country"] ? false : true}
                />
                {fieldValue.country && errors && errors["language"] && (
                  <FormHelperText className={classes.error}>
                    {errors["language"]}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>



          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Fieldsrow
                tableLabel={t("onboarding.users.label")}
                firstColumn={t("onboarding.fieldRow.firstColumn")}
                secondColumn={t("onboarding.fieldRow.secondColumn")}
                addRowLabel={t("onboarding.fieldRow.addRow.label")}
                deleteRowLabel={t("onboarding.fieldRow.deleteRow.label")}
                classes={classes}
                fieldValue={fieldValue}
                rows={roles}
                setRows={setRoles}
                roleOptions={roleOptions}
                setErrorsRow={setErrorsRow}
                errorsRow={errorsRow}
                handleBlur={handleBlur}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          // {...(!validateForm(errors) && { disabled: true })}
          >
            {t("onboarding.submitButton.Form.label")}
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

OnboardingForm.propTypes = {
  externalClass: PropTypes.object,
};
