import React from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useRouteMatch,
  useHistory,
  Router,
} from "react-router-dom";
import Welcome from "../pages/welcome/welcome";
import Login from "../pages/login/login";
import Guide from "../pages/kritique-guide/kritique-guide";
import AccessRequest from "../pages/accessRequest/accessRequest";
import Dashboard from "../pages/dashboard/dashboard";
import ProtectedRoute from "./protectedRoute";

const CustomRoutes = () => {
  const { pathname } = useLocation();
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route exact path={"/"} component={Welcome} />
      <Route exact path={"/login"} component={Login} />
      <Route exact path={"/guide/:guideName"} component={Guide} />
      <ProtectedRoute
        exact
        path={"/dashboard"}
        render={<Dashboard />}
        identifier="dashboard"
      />

      <ProtectedRoute
        exact
        path={"/dashboard/:screen"}
        render={<Dashboard />}
        identifier="dashboard"
      />
      <ProtectedRoute
        exact
        path={"/dashboard/:screen/:id"}
        render={<Dashboard />}
        identifier="dashboard"
      />
      <ProtectedRoute
        exact
        path={"/dashboard/:screen/:id/:brand"}
        render={<Dashboard />}
        identifier="dashboard"
      />
      <ProtectedRoute
        exact
        path={"/dashboard/:screen/:id/:brand/:locale"}
        render={<Dashboard />}
        identifier="dashboard"
      />

      <ProtectedRoute
        exact
        path={"/access-request"}
        render={<AccessRequest />}
        identifier="access"
      />
      <ProtectedRoute
        path={"/access-request/:screen"}
        render={<AccessRequest />}
        identifier="access"
      />
    </Switch>
  );
};

export default CustomRoutes;
