import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#5e81f4",
      //main: "#1997d2",
    },
    alert: {
      main: "#f45e5e",
    },
    action: {
      selected: "rgba(239, 242, 254, 0.9)",
      hover: "#eff2fe",
    },
  },
  typography: {
    fontFamily: "Open Sans, sans-serif",
    h3: {
      fontSize: "1.4rem",
      lineHeight: "1.8rem",
    },
    button: {
      textTransform: "capitalise",
    },
  },
});
export default theme;
