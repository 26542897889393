import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    padding: 0,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "white",
    borderRadius: "20px",
    boxShadow: "none",
    marginTop: 10,
    padding: 0,
  },
  h2: { fontSize: "40px" },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  label: {
    position: "static",
    marginBottom: theme.spacing(1),
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  submit: {
    width: "auto",
    display: "block",
    margin: theme.spacing(3, "auto", 2),
  },
  error: {
    color: theme.palette.error.main,

  },
  selectField: {
    fontSize: "11px",
    "& .MuiOutlinedInput-root": {
        padding: "3px",
        fontSize: ".8rem"
    }
  },
  autocompleteOption: {
      fontSize: ".8rem",
      padding: "0"
  },
  dropdownStyle: {
    boxShadow: "none",
    borderRadius: 0,
    border: "1px solid #ddd",
    "& .MuiList-root": {
      padding: 0,
      maxHeight: "220px",
    },
    "& .MuiMenuItem-root": {
      padding: "3px 10px",
      fontSize: "13px",
    },
  },
}));

export default useStyles;
