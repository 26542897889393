import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Logo from "../../../assets/img/kritique-logo.svg";
import MobileLogo from "../../../assets/img/kritique-logo-small.svg";

const NavDesktop = (props) => {
  const { classes, userMenu, handleDrawerOpen, handleDrawerClose } = props;
  const { Navigation, Curated } = useSelector((state) => state);
  const { path, url, params } = useRouteMatch();

  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: Navigation.isOpen,
          [classes.drawerClose]: !Navigation.isOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: Navigation.isOpen,
            [classes.drawerClose]: !Navigation.isOpen,
          }),
        }}
      >
        <List className={classes.leftSpacing}>
          <div className={classes.logoContainer}>
            {Navigation.isOpen === false ? (
                <img src={MobileLogo} className={classes.mobilelogo} alt="logo" />
            ) : (
                <img src={Logo} className={classes.logo} alt="logo" />
            )}
          </div>
          {userMenu.map((text, index) => (
            <Link
              to={`/dashboard/${text}`}
              key={index}
              className={classes.link}
            >
              <ListItem
                button
                className={classes.navButton}
                selected={params.screen === text ? true : false}
              >
                <ListItemIcon>
                  {(() => {
                    if(Curated.roles[text]["title"] === "Moderation") {
                      return <EventAvailableOutlinedIcon className={classes.icon} />
                    } else if(Curated.roles[text]["title"] === "Report") {
                      return <TrendingUpOutlinedIcon className={classes.icon} />
                    }else if(Curated.roles[text]["title"] === "Configure") {
                      return <SettingsIcon className={classes.icon} />
                    } else if(Curated.roles[text]["title"] === "Support") {
                      return <HelpOutlineOutlinedIcon className={classes.icon} />
                    } else if(Curated.roles[text]["title"] === "User Management") {
                      return <PersonOutlineOutlinedIcon className={classes.icon} />
                    } else if(Curated.roles[text]["title"] === "Developer Portal") {
                      return <IntegrationInstructionsOutlinedIcon className={classes.icon} />
                    } else {
                      return <MailIcon className={classes.icon} />
                    }
                  })()}
                </ListItemIcon>
                <ListItemText
                  primary={Curated.roles[text]["title"]}
                  classes={{ primary: classes.navText }}
                  className={`${classes.nav} ${
                    !Navigation.isOpen && classes.hideNav
                  }`}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>

      {Navigation.isOpen === false ? (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          //edge="start"
          className={classes.menuButton}
        >
          <ChevronRightIcon className={classes.arrowButton} />
        </IconButton>
      ) : (
        <IconButton
          color="inherit"
          aria-label="close drawer"
          onClick={handleDrawerClose}
          className={classes.menuButton}
        >
          <ChevronLeftIcon className={classes.arrowButton} />
        </IconButton>
      )}
    </>
  );
};
NavDesktop.propTypes = {
  classes: PropTypes.object.isRequired,
  userMenu: PropTypes.array.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
};
export default NavDesktop;
