import {
  CURATE_ROLES,
  CURATE_CAPABILITIES,
  RESET_CURATE,
} from "../actions/types";
const initialState = {};

export default function (state = initialState, { type, payload = {} }) {
  switch (type) {
    case CURATE_ROLES:
      console.log("CURATE_ROLES");
      return {
        ...state,
        ...payload,
      };
    case CURATE_CAPABILITIES:
      console.log("CURATE_CAPABILITIES");
      return {
        ...state,
        ...payload,
      };
    case RESET_CURATE:
      return {
        ...payload,
      };
    default:
      return state;
  }
}
