import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Logo from "../../../assets/img/kritique-logo.svg";

const IntermediaryScreen = (props) => {
  const { classes } = props;
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <div className={classes.accessWrapper}>
      <div className={classes.wrapper}>
        <Container maxWidth="xs" className={classes.login}>
          <img src={Logo} className={"App-logo"} alt="logo" width={130} height={35} />
          <Typography component="div" className={classes.errorStyle}>
            <Typography component="span" className={classes.errorText}>
              {"Unauthorised access"}
            </Typography>
            {`${t("login.error.footnote")}`}
            <a href={t("kana.brandAccessForm.link")} target="_blank" aria-label={t("newTab.arialabel")}><br></br><br></br>{" Raise ticket now"}</a>
          </Typography>
          <div className={classes.paper}>
            <Container component="span" className={classes.copyText}>
                &#169; {new Date().getFullYear()} Unilever
            </Container>
          </div>
        </Container>
      </div>
    </div>
  );
};
IntermediaryScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default IntermediaryScreen;
