import React, { lazy } from "react";
import { enums } from "@unilever-kritique/kritique-common/enums";
import t from "../src/locales/en-US.json";

const Reports = lazy(() =>
  import("./components/templates/reports/reportsScreen")
);
const Configure = lazy(() =>
  import("./components/templates/configure/configureScreen")
);
const Developer = lazy(() =>
  import("./components/templates/developerManagement/developerScreen")
);
const Support = lazy(() =>
  import("./components/templates/support/supportScreen")
);
const Management = lazy(() =>
  import("./components/templates/userManagement/userManagementScreen")
);
const Moderation = lazy(() =>
  import("./components/templates/moderation/moderationScreen")
);
const OnboardingForm = lazy(() =>
  import("./components/templates/onboardingForm/onboardingForm")
);

const WidgetConfigure = lazy(() =>
  import("./components/templates/widgetConfiguration/widgetConfiguration")
);
const ReviewApi = lazy(() =>
  import("./components/templates/reviewApi/reviewApiScreen")
);
const QuestionApi = lazy(() =>
  import("./components/templates/questionsApi/questionsApiScreen")
);
const UserApi = lazy(() =>
  import("./components/templates/usersApi/usersApiScreen")
);
const ConfigurationApi = lazy(() =>
  import("./components/templates/configurationApi/configurationApiScreen")
);

const ReviewConfigure = lazy(() =>
  import("./components/templates/reviewSyndication/reviewSyndication")
);

const EmailConfigure = lazy(() =>
  import("./components/templates/emailConfiguration/emailConfiguration")
);
export const questionCarelinesModeration = enums.questionCarelinesModeration;

const getQuestionStatuses = (statusEnum) => {
  let statuses = {}
  statusEnum.forEach(element => {
    if(element === 'pending'){
      statuses[element] = t['filter.question.status.pending']
    }else if(element === 'publiclyanswered'){
      statuses[element] = t['filter.question.status.publiclyanswered']
    }else {
      statuses[element] = t['filter.question.status.privatelyanswered']
    }
  });
  return statuses
}

const Config = {
  defaultDashboard: "moderation",
  defaultSupport: "onboard-form",
  defaultConfigure: "widget-configuration",
  defaultManagement: "user-management",
  defaultDeveloper: "reviews-api",
  noSyndication: ["Kritique"],
  kritiqueAdmin: "KQ_Admin",
  brandAdmin: "Brand_Admin",
  countryList: enums.country,
  languageList: enums.language,
  brandList: enums.brandCodes,
  legalConfig: enums.legalConfig,

  supportMenu: {
    ["onboard-form"]: "User Onboarding Form",
  },
  configureMenu: {
    ["widget-configuration"]: "Widget Configurations",
    ["email-configuration"]: "Email Configurations",
    ["review-configuration"]: "Review Syndication",
  },
  developerMenu: {
    ["reviews-api"]: "Reviews API",
    ["questions-api"]: "Questions API",
    ["configurations-api"]: "Configurations API",
    ["users-api"]: "Users API",
  },
  managementMenu: {
    ["user-management"]: "User Management",
  },
  visibleRecords: 10,
  modelMsgTimer: 5000,
  rejectedItems: [
    "Abusive/inappropriate Content",
    "Competitor Reference",
    "Customer Complaint",
    "Review applies to Different Entity",
    "Personally Identifiable Information",
    "Review is in a Foreign Language",
    "Others",
  ],
  startDate: new Date(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).setUTCHours(0, 0, 0)
  ).toISOString(),
  endDate: new Date(new Date(Date.now()).setUTCHours(23, 59, 59)).toISOString(),
  filterFacets: {
    entityTypes: enums.entityTypes,
    filterTypes: enums.filterTypes,
    questionSources: enums.questionSources,
    questionMediaSources: enums.questionMediaSources,
    QuestionStatuses: getQuestionStatuses(enums.statusesQuestion.enum),
    statuses: enums.statuses,
    sources: enums.sources,
    ratings: enums.ratings,
    reviewMediaSources: enums.reviewMediaSources,
  },
  screens: {
    management: <Management />,
    moderation: <Moderation />,
    configure: <Configure />,
    developer: <Developer />,
    support: <Support />,
    reports: <Reports />,
    ["onboard-form"]: <OnboardingForm />,
    ["widget-configuration"]: <WidgetConfigure />,
    ["review-configuration"]: <ReviewConfigure />,
    ["email-configuration"]: <EmailConfigure />,
    ["reviews-api"]: <ReviewApi />,
    ["questions-api"]: <QuestionApi />,
    ["configurations-api"]: <ConfigurationApi />,
    ["users-api"]: <UserApi />,
  },
  menuItems: {
    pending: ["rejected", "approved"],
    approved: ["rejected"],
    rejected: ["approved"],
    responded: ["rejected"],
    reported: ["rejected", "approved"],
    questionPending: ["publiclyanswered", "privatelyanswered"],
  },
  editRolesitems: {
    KQ_Admin: ["Moderator", "Agency_User"],
    Brand_Admin: ["Moderator", "Agency_User"],
  },

  initialFormValue(params) {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      page: 1,
      size: Config.visibleRecords,
      rating: [],
      moderatedBy: "",
      brandLocale: [],
      brands: [],
      ratingList: this.filterFacets.ratings,
      statusList: this.filterFacets.statuses,
      questionStatusList: this.filterFacets.QuestionStatuses,
      filterTypeList: this.filterFacets.filterTypes,
      questionSourcesList: this.filterFacets.questionSources,
      questionMedia: this.filterFacets.questionMediaSources,
      reviewStatusList: this.filterFacets.statuses,
      entityTypeList: this.filterFacets.entityTypes,
      AllBrandLocale: [],
      sources: this.filterFacets.sources,
      reviewMedia: this.filterFacets.reviewMediaSources,
      selectedBrand: params ? (params.brand ? params.brand : "") : "",
      selectedLocale: params ? (params.locale ? params.locale : "") : "",
      filterDataType: params ? (params.id ? params.id : "review") : "review",
      filterType: params ? (params.id ? params.id : "review") : "review",
      totalCount: null,
      sort: "createdDate desc",
      status: "pending",
    };
  },
  initialUserDataFormValue() {
    return {
      page: 1,
      size: Config.visibleRecords,
    };
  },
  initalEntityValue() {
    return {
      moderatedBy: "",
      reviewResponse: {
        responseText: "",
        responseDate: "",
        responderName: "",
      },
      status: "",
    };
  },
  brand: enums.brands,
  locales: enums.locales,
  reviewSources() {
    return enums.sources.filter((i) => this.noSyndication.indexOf(i) === -1);
  },
};

export default Config;
