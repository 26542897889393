import { IS_OPEN, NAV_MENU_LIST, RESET_NAV } from "../actions/types";

const initialState = {
  isOpen: false,
  navMenuList: [],
};

//Reducers
export default function (state = initialState, { type, payload = {} }) {
  switch (type) {
    case IS_OPEN:
      return {
        ...state,
        ...payload,
      };
    case NAV_MENU_LIST:
      return {
        ...state,
        ...payload,
      };
    case RESET_NAV:
      return {
        ...payload,
      };

    default:
      return state;
  }
}
