const { IS_OPEN, NAV_MENU_LIST, INIT_NAV } = require("./types");

export const navigationState = (e) => (disptch) => {
  disptch({
    type: IS_OPEN,
    payload: {
      isOpen: e,
    },
  });
  return Promise.resolve(e);
};

export const setNavMenu = (e) => (disptch) => {
  disptch({
    type: NAV_MENU_LIST,
    payload: {
      navMenuList: e,
    },
  });
  return Promise.resolve();
};

export const initNav = (e) => (disptch) => {
  dispatchEvent({
    type: INIT_NAV,
    payload: {
      navMenuList: e,
    },
  });
};
