import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Container from "@material-ui/core/Container";
import UseStyles from "./style";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import HeroLayout from "../../molecules/heroLayout/heroLayout";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../../assets/img/welcome-banner.jpg";

function WelcomeHero() {
  const classes = UseStyles();
  const { t } = useTranslation();
  const { Auth } = useSelector((state) => state);
  //local state assigend for dynamic login/dashboard button
  const [loggedIn, setloggedIn] = useState(false);


  return (
    <HeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: "none" }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Container className={classes.contentBlock} maxWidth="md">
        <Typography
          color="inherit"
          align="center"
          variant="h2"
          className={classes.h2}
          marked="center"
        >
          {t("welcome.welcomeHero.title")}
        </Typography>
        <Typography
          color="inherit"
          align="center"
          variant="h3"
          className={classes.h3}
        >
          {t("welcome.welcomeHero.content")}
        </Typography>

        <Button
          color="primary"
          variant="contained"
          size="large"
          className={classes.button}
          component="a"
          href="/login"
        >
          {t("welcome.welcomeHero.loginButton")}
        </Button>
      </Container>
    </HeroLayout>
  );
}

export default WelcomeHero;
