import {
  SUBMIT_FORM,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../actions/types";

const initialState = {};

//Reducers
export default function (state = initialState, { type, payload = {} }) {
  switch (type) {
    case SUBMIT_FORM:
      return {
        ...state,
        ...payload,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
      };

    case LOGIN_FAIL:
      return {
        ...state,
        ...payload,
      };

    case LOGOUT:
      return {
        ...payload,
      };

    default:
      return state;
  }
}
