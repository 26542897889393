import { setLocalStoreItem } from "../helpers/localStoreHandler";
const { AUTH_SUCCESS } = require("./types");

export const authForm = (e) => (disptch) => {
  disptch({
    type: AUTH_SUCCESS,
    payload: {
      isLoggedIn: true,
      user: e,
    },
  });
  return Promise.resolve();
};

