import React, { lazy, Suspense } from "react";
import Link from "@material-ui/core/Link";
import UseStyles from "./style";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import ProgressBar from "../../components/molecules/progressBar/progressBar";
import Header from "../../components/organisms/header/header";
import Footer from "../../components/organisms/footer/footer";
import FullWidthContentBlock from "../../components/molecules/fullWidthContentBlock/fullWidthContentBlock";
import Hero from "../../components/organisms/welcomHero/welcomeHero";
import SideBySideBlock from "../../components/organisms/sideBySideBlock/sideBySideBlock";

const Welcome = () => {
  const { t } = useTranslation();
  const classes = UseStyles();
  return (
    <>
      <Header />
      <Suspense fallback={<ProgressBar />}>
        <Hero />
      </Suspense>
      <Suspense fallback={<ProgressBar />}>
        <SideBySideBlock classes={classes}>
          <div className={classes.gutter30}>
            <div className={classes.contetnWrapper}>
              <Typography
                color="inherit"
                align="center"
                variant="h2"
                className={classes.h2}
                marked="center"
              >
                {t("welcome.sideBySideBlock.first.title")}
              </Typography>
              <Typography
                color="inherit"
                align="center"
                variant="body1"
                className={classes.alignLeft}
              >
                {t("welcome.sideBySideBlock.first.text")}
              </Typography>
            </div>
            <div className={classes.contetnWrapper}>
              <Typography
                color="inherit"
                align="center"
                variant="h2"
                className={classes.h2}
                marked="center"
              >
                {t("welcome.sideBySideBlock.second.title")}
              </Typography>
              <Typography
                color="inherit"
                align="center"
                variant="body1"
                className={classes.alignRight}
              >
                {t("welcome.sideBySideBlock.second.text")}
              </Typography>
            </div>
          </div>
        </SideBySideBlock>
      </Suspense>
      <FullWidthContentBlock classes={classes}>
        <Grid
          item
          md={6}
          sm={12}
          className={classes.fullWidthContentBlockContentWrapper}
        >
          <Typography
            color="inherit"
            align="center"
            variant="h2"
            className={`${classes.h2}  ${classes.alignCenter}`}
            marked="center"
          >
            {t("welcome.fullWidthContentBlock.title")}
          </Typography>
          <Typography
            color="inherit"
            align="center"
            variant="body1"
            className={classes.alignCenter}
          >
            {t("welcome.fullWidthContentBlock.text.part1")}
            <Link href="mailto:info.kritique@unilever.com">Kritique team</Link>
            {t("welcome.fullWidthContentBlock.text.part2")}
          </Typography>
        </Grid>
      </FullWidthContentBlock>
      <Footer />
    </>
  );
};

export default Welcome;
