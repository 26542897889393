import { makeStyles } from "@material-ui/core/styles";
import backgroundImage from "../../assets/img/welcome-right-bg.jpg";

const useStyles = makeStyles((theme) => ({
  h2: {
    fontWeight: `300`,
    fontSize: `30px`,
    textTransform: `uppercase`,
    textAlign: `left`,
    marginBottom: `20px`,
  },
  alignLeft: {
    textAlign: `left`,
  },
  alignRight: {
    textAlign: `left`,
  },
  alignCenter: {
    textAlign: `center`,
  },
  gutter30: {
    padding: `30px 35px 30px 15px`,
  },
  contetnWrapper: {
    maxWidth: `527px`,
    marginLeft: `auto`,
    marginBottom: `35px`,
  },
  sideBySidebBackground: {
    backgroundImage: `url(${backgroundImage})`,
  },
  blockColor: {
    background: `#2d2d2d`,
  },
  fullWidthContentBlockContentWrapper: {
    margin: `auto !important`,
    padding: `60px 15px`,
    color: `#ffffff`,
  },
}));
export default useStyles;
