import axios from "axios";
import { setLocalStoreItem } from "../helpers/localStoreHandler";
const API_ENDPOINT = process.env.REACT_APP_USER_API_URL;

const login = (username) => {
  return axios
    .post(
      API_ENDPOINT + "/user/login/",
      {
        username
      },
      { "Content-Type": "application/json", withCredentials: true }
    )
    .then((response) => {
      var cookieName = 'jwt';
      var cookieValue = response.headers.token;
      setLocalStoreItem("usercookie",cookieName + "=" + cookieValue + ";path=/");
      if (response.data.accessToken) {
        setLocalStoreItem("user", JSON.stringify(response.data));
      }
      return response.data;
    })
    .catch((e) => {
      return Promise.reject(
        e.response && e.response.data && e.response.data.error || "Unable to connect server. Please try later"
      );
    });
};

const logout = () => {
  return axios
    .get(API_ENDPOINT + "/user/logout/", { withCredentials: true })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return Promise.reject(
        e.response && e.response.data && e.response.data.error || "Unable to connect server. Please try later"
      );
    });
};

export default {
  login,
  logout,
};
